/* eslint-disable react/display-name */
import React from 'react';
import { Link, graphql } from 'gatsby';
import Image from 'gatsby-image';

export default ({ data }) => {
  return (
    <>
      <div className="projects__title">Projects</div>
      <div className="projects__Wrapper">
        <div className="projects__project1Text">
          <strong>Luxe Bible</strong>
          <br />
          <br />
          Featuring the newest, coolest happenings and the latest hangouts for
          digital readers,{' '}
          <a href="https://luxebible.com/" target="blank">
            Luxe Bible
          </a>{' '}
          is the go-to-guide for what’s hot in London, Newcastle, Manchester and
          across the globe!
          <br />
          <br />
          Lindsey is excited to join the Luxe Bible team, making a green
          lifestyle exciting and accessible.
        </div>
        <Image
          className="projects__project1Image"
          fluid={
            data.allContentfulLindseyProjectImages.nodes[0].projectImages[3]
              .fluid
          }
        />
        <div className="projects__project2Text">
          <strong>The Wild Watch</strong>
          <br />
          <br />
          Lindsey is delighted to be patron of The Wild Watch, Nidderdale’s
          biggest ever wildlife survey.
          <br />
          <br />
          Working with volunteers, the project intends to gather information on
          over 50 species right across the AONB which will then be carefully
          analysed and help form a conservation plan for the future.
          <br />
          <br />
          If you’re interested in volunteering you can find{' '}
          <a
            href="https://nidderdaleaonb.org.uk/about-us/nidderdale-aonb-projects/the-wild-watch/"
            target="blank"
          >
            more information here.
          </a>
        </div>
        <Image
          className="projects__project2Image"
          fluid={
            data.allContentfulLindseyProjectImages.nodes[0].projectImages[2]
              .fluid
          }
        />
        <div className="projects__project3Text">
          <strong>The Yorkshire Dales Millennium Trust</strong>
          <br />
          <br />
          <a href="https://www.ydmt.org/" target="blank">
            Yorkshire Dales Millennium Trust
          </a>{' '}
          are a small charity doing big things to support the people, landscape
          and wildlife of the Dales.
          <br />
          <br />
          Their vital work has helped to plant 1.4 million trees and secure the
          future of over 700 hectares of wildflower hay meadows, creating
          fantastic habitats for wildlife across the region.
          <br />
          <br />
          As Patron of the Yorkshire Dales Millennium Trust, Lindsey supports
          the great work being done to nuture this special place and the people
          who call it home.
        </div>
        <Image
          className="projects__project3Image"
          fluid={
            data.allContentfulLindseyProjectImages.nodes[0].projectImages[1]
              .fluid
          }
        />
        <div className="projects__project4Text">
          <strong>UK Wild Otter Trust</strong>
          <br />
          <br />
          Lindsey is proud to be a patron of the UK Wild Otter Trust.{' '}
          <a href="http://www.ukwildottertrust.org/" target="blank">
            For more information click here.
          </a>
        </div>
        <Image
          className="projects__project4Image"
          fluid={
            data.allContentfulLindseyProjectImages.nodes[0].projectImages[0]
              .fluid
          }
        />
        <div className="projects__left"></div>
        <div className="projects__right"></div>
      </div>
      <div className="projects__future">
        If you would like to contact Lindsey about a future project,{' '}
        <Link to="/contact">please do get in touch.</Link>
      </div>
    </>
  );
};

export const pageQuery = graphql`
  query {
    allContentfulLindseyProjectImages {
      nodes {
        projectImages {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;
